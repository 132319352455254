import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  HostListener,
} from "@angular/core";
import SignaturePad from "signature_pad";
import { ModalController, ToastController } from "@ionic/angular";
import {
  blobToFile,
  dataUrlToFile,
  loadImageFromUrl,
} from "@app/shared/util/file";
import { Result, Success, Failure } from "@app/shared/util/types/result";
import { AppError } from "@app/shared/util/errors/error";

// declare var SignaturePad: any;

@Component({
  selector: "app-signature",
  templateUrl: "./signature.page.html",
  styleUrls: ["./signature.page.scss"],
})
export class SignaturePage implements AfterViewInit {
  @ViewChild("signaturePad") canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild("auxCanvas") auxCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild("container") container: ElementRef<HTMLElement>;
  pad: SignaturePad;

  constructor(
    private modalController: ModalController,
    private toastController: ToastController
  ) {}

  ngAfterViewInit() {
    const width = this.container.nativeElement.getBoundingClientRect().width;
    const height = this.container.nativeElement.offsetHeight;
    this.getContainerHeight();
  }

  @HostListener("window:orientationchange", ["$event"])
  onOrientationChange(event) {
    this.pad.clear();
    setTimeout(() => {
      this.pad.clear();
      const width = this.container.nativeElement.getBoundingClientRect().width;
      const height = this.container.nativeElement.offsetHeight;
      this.canvas.nativeElement.width = width;
      this.canvas.nativeElement.height = height;
    }, 1000);
  }

  getContainerHeight() {
    const width = this.container.nativeElement.getBoundingClientRect().width;
    const height = this.container.nativeElement.offsetHeight;
    if (width === 0 || height === 0) {
      setTimeout(this.getContainerHeight.bind(this), 1000);
    } else {
      this.canvas.nativeElement.width = width;
      this.canvas.nativeElement.height = height;
      this.pad = new SignaturePad(this.canvas.nativeElement);
    }
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  async onConfirm() {
    const dataUrl = this.pad.toDataURL();
    let result: Result<File>;
    if (this.canvas.nativeElement.height > this.canvas.nativeElement.width) {
      try {
        const rotatedFile = await this.rotateImage(dataUrl);
        result = new Success(rotatedFile);
      } catch (error) {
        // TODO: sentry
        result = new Failure(AppError.parse(error));
      }
    } else {
      result = await dataUrlToFile(this.pad.toDataURL(), "assinatura");
    }

    if (result.isSuccess) {
      await this.modalController.dismiss(result.data);
    } else {
      // TODO: sentry
      const toast = await this.toastController.create({
        message: "Não foi possível registrar sua assinatura",
        color: "danger",
      });
      await toast.present();
      await this.modalController.dismiss();
    }
  }

  async rotateImage(dataUrl: string): Promise<File> {
    const image = await loadImageFromUrl(dataUrl);

    this.auxCanvas.nativeElement.width = image.height;
    this.auxCanvas.nativeElement.height = image.width;
    const context = this.auxCanvas.nativeElement.getContext("2d");
    context.fillStyle = "#ffffffff";
    context.fillRect(0, 0, image.height, image.width);

    context.save();

    // context.translate(image.width * 0.5, image.height * 0.5);
    context.rotate(Math.PI / 2);
    context.translate(-image.width * 0.0, -image.height * 1.0);

    context.drawImage(image, 0, 0);
    context.restore();

    this.auxCanvas.nativeElement.style.display = "block";
    return new Promise((resolve) => {
      this.auxCanvas.nativeElement.toBlob((blob) =>
        resolve(blobToFile(blob, "assinatura"))
      );
    });
  }

  onClear() {
    this.pad.clear();
  }
}
