import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { IonInput, ModalController } from "@ionic/angular";

@Component({
  selector: "app-email-modal",
  templateUrl: "./email-modal.component.html",
  styleUrls: ["./email-modal.component.scss"],
})
export class EmailModalComponent implements OnInit {
  @ViewChild("input") input: IonInput;

  email: string;
  constructor(private modalController: ModalController) {}

  ngOnInit() {
    setTimeout(() => {
      this.input.setFocus();
    }, 500);
  }

  onSubmit() {
    if (this.validateEmail()) {
      this.modalController.dismiss(this.email);
    }
  }

  validateEmail() {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
  }
}
