<ion-text class="title">Termos de uso da Plataforma Mappa Dealer</ion-text>
<p class="terms" [innerHTML]="terms.content"></p>
<div class="acceptance">
  <ion-checkbox
    class="acceptance__checkbox"
    [(ngModel)]="isChecked"
  ></ion-checkbox>
  <ion-label class="acceptance__text" (click)="isChecked = !isChecked"
    >Li e aceitos o Termos de Uso do MappaDealer</ion-label
  >
</div>
<ion-button class="submit" (click)="onSubmit()" [disabled]="!isChecked"
  >Aceitar Termos de Uso</ion-button
>
