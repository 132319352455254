import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { NotFoundPage } from "./presentation/not-found/not-found.page";
import { DocumentsPage } from "./presentation/documents/documents.page";
import { HttpClientModule } from "@angular/common/http";
import { SentryErrorHandler } from "./shared/services/sentry-error-handler.service";
import { SignaturePage } from "./presentation/signature/signature.page";
import { ImageViewerPage } from "./presentation/image-viewer/image-viewer.page";
import { TermsModalComponent } from "./presentation/terms-modal/terms-modal.component";
import { FormsModule } from "@angular/forms";
import { EmailModalComponent } from "./presentation/email-modal/email-modal.component";

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPage,
    DocumentsPage,
    SignaturePage,
    ImageViewerPage,
    TermsModalComponent,
    EmailModalComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
