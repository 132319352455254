import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AcceptanceUsecase } from "./acceptance.usecase";

@Injectable({
  providedIn: "root",
})
export class TermsDetailGuard implements CanActivate {
  constructor(private acceptance: AcceptanceUsecase, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const result = await this.acceptance.init(route.params.token);

    if (result.isLinkExpired) {
      this.router.navigate(["/", "404"], { replaceUrl: true });
      return false;
    } else {
      // allow to get to the page, so  the page can show an error
      return true;
    }
  }
}
