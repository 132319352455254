import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { NotFoundPage } from "./presentation/not-found/not-found.page";
import { DocumentsPage } from "./presentation/documents/documents.page";
import { NotFoundRedirectionGuard } from "./domain/usecases/notfound-redirection.guard";
import { TermsDetailGuard } from "./domain/usecases/terms-detail.guard";

const routes: Routes = [
  {
    path: "404",
    component: NotFoundPage,
    canActivate: [NotFoundRedirectionGuard],
  },
  {
    path: "link/:token",
    component: DocumentsPage,
    canActivate: [TermsDetailGuard],
  },
  {
    path: "",
    redirectTo: "404",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
