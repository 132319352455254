import * as Sentry from "@sentry/browser";
import { environment } from "@environments/environment";
import { Injectable, ErrorHandler } from "@angular/core";

Sentry.init({
  dsn:
    "https://21698940c62245d1934e6912b4c05fcc@o355008.ingest.sentry.io/5413875",
  release: `mappa-dealer-public@${environment.name}@${environment.version}`,
  environment: environment.name,
  integrations: [
    new Sentry.Integrations.TryCatch({
      XMLHttpRequest: false,
    }),
  ],
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    Sentry.captureException(error);
    throw error;
  }
}
