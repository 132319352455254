import { CanLoad, CanActivate } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NotFoundRedirectionGuard implements CanActivate {
  canActivate() {
    // window.location.href = "/alo";
    return true;
  }
}
