<div class="header">
  <ion-icon
    class="header__close"
    name="close-outline"
    (click)="onDismiss()"
  ></ion-icon>
  <ion-text class="header__title"
    >Desenhe a assinatura usando o dedo ou a caneta</ion-text
  >
  <ion-icon
    class="header__confirm"
    name="checkmark-circle"
    (click)="onConfirm()"
  ></ion-icon>
</div>
<div #container class="signature-pad">
  <canvas #signaturePad width="0" height="0"></canvas>
  <div class="signature-pad__line"></div>
</div>
<div class="footer">
  <div class="footer__clean ion-activatable" (click)="onClear()">
    LIMPAR
    <ion-ripple-effect></ion-ripple-effect>
  </div>
  <ion-button
    class="footer__confirm confirm"
    color="primary"
    (click)="onConfirm()"
  >
    CONFIRMAR
  </ion-button>
  <!-- <div class="footer__clean ion-activatable confirm" (click)="onConfirm()">
    
    <ion-ripple-effect></ion-ripple-effect>
  </div> -->
</div>
<canvas #auxCanvas style="display: none;"></canvas>
