import { Injectable } from "@angular/core";
import { DocAnswerEntity } from "@app/data/entities/doc-answer.entity";
import { DocAnswerRepository } from "@app/data/repositories/doc-answer.repository";
import { Result } from "@app/shared/util/types/result";
import { DocFileEntity } from "@app/data/entities/document-file.entity";
import { SignaturePage } from "@app/presentation/signature/signature.page";
import { combineLatest } from "rxjs";
import { AcceptanceUsecase } from "./acceptance.usecase";
import { switchMap, take } from "rxjs/operators";
import { ModalController, ToastController } from "@ionic/angular";

@Injectable()
export class UploadUsecase {
  constructor(
    private docAnswerRepository: DocAnswerRepository,
    private acceptance: AcceptanceUsecase,
    public toastController: ToastController,
    public modalController: ModalController
  ) {}

  busy: boolean;
  progress = 0.0;
  onInputUploadClick(ev: MouseEvent, doc: DocAnswerEntity) {
    ev.preventDefault();

    if (doc.node_model?.doc_type?.is_signature === true) {
      this.onSignature(doc);
    } else {
      const el: HTMLElement = document.getElementById(`doc-${doc.id}`);
      el.click();
    }
  }

  onFileSelected(ev: Event, doc: DocAnswerEntity) {
    try {
      const files: File[] = (ev.target as any).files;
      if (files.length > 0) {
        const file = files[0];
        this.uploadEngine(doc, file);
      }
    } catch (error) {
      this.busy = false;
      this.progress = 0;
      // TODO: sentry
      console.log(error);
    }
  }

  async onSignature(doc: DocAnswerEntity) {
    const modal = await this.modalController.create({
      component: SignaturePage,
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.uploadEngine(doc, data);
    }
  }

  private uploadEngine(doc: DocAnswerEntity, file: File) {
    this.busy = true;
    combineLatest([this.acceptance.acceptanceToken$, this.acceptance.link$])
      .pipe(
        take(1),
        switchMap(([acceptanceToken, link]) => {
          return this.docAnswerRepository.upload({
            documentAnswerId: doc.id,
            token: link.token,
            acceptanceToken,
            file,
          });
        })
      )
      .subscribe(async (result: Result<DocFileEntity> | number) => {
        if (typeof result === "number") {
          this.progress = result;
        } else if (result.isSuccess) {
          this.busy = false;
          this.progress = 0;
          const successToast = await this.toastController.create({
            color: "success",
            message: "Documento enviado",
            duration: 3000,
          });
          await successToast.present();
          this.acceptance.updateDocAnswer(doc, result.data);
        } else if (result.isFailure) {
          this.busy = false;
          this.progress = 0;
          const failureToast = await this.toastController.create({
            color: "danger",
            message: "Não foi possível enviar o documento",
            duration: 3000,
          });
          await failureToast.present();
        }
      });
  }
}
