import { Component, Input, OnInit } from "@angular/core";
import { TermsEntity } from "@app/data/entities/terms.entity";
import { ModalController } from "@ionic/angular";
import { EmailModalComponent } from "../email-modal/email-modal.component";

@Component({
  selector: "app-terms-modal",
  templateUrl: "./terms-modal.component.html",
  styleUrls: ["./terms-modal.component.scss"],
})
export class TermsModalComponent {
  @Input() terms: TermsEntity;
  isChecked = false;

  constructor(private modalController: ModalController) {}

  async onSubmit() {
    const modal = await this.modalController.create({
      component: EmailModalComponent,
      cssClass: "not-fullscreen-modal",
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    const email = data;

    setTimeout(() => {
      if (typeof email === "string") {
        this.modalController.dismiss(email);
      }
    });
  }
}
