<ion-content>
  <ng-container
    *ngIf="acceptance.busy$ | async; then loading; else resolved"
  ></ng-container>
  <ng-template #loading>
    <div class="loading-container">
      <img class="logo-spinner" src="assets/images/logo.jpg" />
    </div>
  </ng-template>
  <ng-template #resolved>
    <ng-container
      *ngIf="(acceptance.state$ | async).isAcceptanceGenericError; else success"
    >
      <div class="main__error">
        <img class="main__error-logo" src="assets/images/logo.jpg" />
        <p class="main__error-center">
          Ocorreu um erro. Por favor, entre em contato com o suporte.
        </p>
      </div>
    </ng-container>
    <ng-template #success>
      <div class="main">
        <img class="logo" src="assets/images/logo.jpg" />
        <ion-text class="title"
          >Processo {{(acceptance.link$ | async)?.id}}</ion-text
        >
        <ion-text class="subtitle"
          >Cliente: {{(acceptance.link$ | async)?.client_name}}</ion-text
        >
        <ion-text class="instructions"
          >Insira abaixo os arquivos para cada tipo de documento</ion-text
        >
        <ion-grid class="content">
          <ng-container
            *ngFor="let doc of (acceptance.link$ | async)?.doc_answers"
          >
            <ion-row>
              <ion-text class="document-title"
                >{{doc.node_model.title}}</ion-text
              >
            </ion-row>
            <ion-row class="document-content">
              <div class="content__item">
                <div
                  class="document-add"
                  (click)="upload.onInputUploadClick($event, doc)"
                >
                  <!-- (click)="upload.onSignature(doc)" -->
                  <ion-icon name="add-outline"></ion-icon>
                </div>
                <input
                  [id]="'doc-' + doc.id"
                  type="file"
                  style="display: none;"
                  (change)="upload.onFileSelected($event, doc)"
                  onclick="this.value = null"
                />
              </div>
              <ng-container *ngFor="let file of doc.files">
                <div
                  class="content__item"
                  *ngIf="deleteUsecase.deleted[file.id] !== true"
                  size="6"
                  size-md="4"
                  size-lg="3"
                >
                  <ion-icon
                    class="content__item__delete"
                    slot="icon-only"
                    name="trash-outline"
                    (click)="deleteUsecase.delete(doc, file)"
                  ></ion-icon>
                  <ion-img
                    class="document-image"
                    *ngIf="isImage(file)"
                    [src]="file.file"
                    (click)="openImage(file)"
                  ></ion-img>
                  <div *ngIf="isPdf(file)" class="document-file">
                    <ion-icon name="document-outline"></ion-icon>
                    <ion-text>.pdf</ion-text>
                  </div>
                  <div
                    *ngIf="!isImage(file) && !isPdf(file)"
                    class="document-file"
                  >
                    <ion-icon name="document-outline"></ion-icon>
                    <ion-text>.{{file.extension}}</ion-text>
                  </div>
                  <span class="document-filename">{{file.name}}</span>
                </div>
              </ng-container>
            </ion-row>
          </ng-container>
        </ion-grid>
      </div>
    </ng-template>
  </ng-template>
  <div class="overlay" *ngIf="upload.busy">
    <ion-card>
      <ion-card-content>
        Enviando documento...
        <ion-progress-bar [value]="upload.progress"></ion-progress-bar>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
