import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  templateUrl: "./image-viewer.page.html",
  styleUrls: ["image-viewer.page.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ImageViewerPage {
  @Input() url;
  @ViewChild("slider", { read: ElementRef }) sliderEl: ElementRef;

  sliderOpts = {
    zoom: {
      maxRatio: 5,
    },
  };

  constructor(private modalController: ModalController) {}

  zoomIn() {
    this.sliderEl?.nativeElement?.swiper?.zoom?.in();
  }
  zoomOut() {
    this.sliderEl?.nativeElement?.swiper?.zoom?.out();
  }

  close() {
    this.modalController.dismiss();
  }
}
