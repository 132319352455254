import { AppError } from "./errors/error";
import { Failure, Result, Success } from "./types/result";

export function blobToFile(theBlob: Blob, fileName: string): File {
  const typeChunks = theBlob.type.split("/");
  const type = typeChunks[typeChunks.length - 1];
  const name = `${fileName}.${type}`;

  // Cast to a File() type
  return new File([theBlob], name);
}

export async function dataUrlToFile(
  data: string,
  filename: string = "file"
): Promise<Result<File>> {
  try {
    const res = await fetch(data);
    const blob = await res.blob();
    const file = blobToFile(blob, filename);
    return new Success(file);
  } catch (error) {
    return new Failure(AppError.parse(error));
  }
}

export function loadImageFromUrl(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = (e) => resolve(image);
    image.onerror = reject;
    image.src = url;
  });
}
