<ion-text class="title"
  >Informe seu <b>e-mail</b> para que possamos enviar os Termos de Uso</ion-text
>
<ion-item>
  <ion-label position="floating">E-mail</ion-label>
  <ion-input #input [(ngModel)]="email" type="email"></ion-input>
</ion-item>
<ion-button
  class="submit"
  (click)="onSubmit()"
  [disabled]="!this.validateEmail()"
  >Aceitar termos de uso</ion-button
>
