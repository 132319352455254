<ion-content [fullscreen]="true">
  <ion-item class="controls" lines="none">
    <ion-button (click)="close()" fill="clear" class="light-text">
      <ion-icon name="close" slot="start"></ion-icon>
      Voltar
    </ion-button>
    <span class="spacer"></span>
    <ion-button (click)="zoomIn()" fill="clear" class="light-text">
      <ion-icon name="add" slot="icon-only"></ion-icon>
    </ion-button>
    <ion-button (click)="zoomOut()" fill="clear" class="light-text">
      <ion-icon name="remove" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-slides [options]="sliderOpts" #slider>
    <ion-slide>
      <div class="swiper-zoom-container">
        <img [src]="url" />
      </div>
    </ion-slide>
  </ion-slides>
</ion-content>
