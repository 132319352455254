import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppError } from "@app/shared/util/errors/error";
import { Failure, Result, Success } from "@app/shared/util/types/result";
import { environment } from "@environments/environment";
import { DocAnswerEntity } from "../entities/doc-answer.entity";
import { DocFileEntity } from "../entities/document-file.entity";
import { LinkEntity } from "../entities/link.entity";
import { TermsAcceptanceEntity } from "../entities/terms-acceptance.entity";
import { TermsEntity } from "../entities/terms.entity";

@Injectable({
  providedIn: "root",
})
export class LinkRepository {
  showUrl = "public/link/:token/";
  deleteFileUrl =
    "public/link/:token/document_answer/:documentAnswerId/document_file/:documentFileId/";
  showTermsUrl = "public/link/:token/terms-of-use/";
  acceptTermsUrl = "public/link/:token/accept-terms/";

  constructor(protected readonly http: HttpClient) {}

  async fetchTerms(token: string): Promise<Result<TermsEntity>> {
    try {
      const baseUrl = environment.apiUrl;
      const url = this.showTermsUrl.replace(/:token/g, token);
      const res = await this.http
        .get<TermsEntity>(`${baseUrl}/${url}`)
        .toPromise();

      return new Success(res);
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async acceptTerms(
    token: string,
    email: string
  ): Promise<Result<TermsAcceptanceEntity>> {
    try {
      const baseUrl = environment.apiUrl;
      const url = this.acceptTermsUrl.replace(/:token/g, token);
      const res = await this.http
        .post<TermsAcceptanceEntity>(`${baseUrl}/${url}`, { email })
        .toPromise();

      return new Success(res);
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async show(
    token: string,
    termsAcceptanceToken: string
  ): Promise<Result<LinkEntity>> {
    try {
      const baseUrl = environment.apiUrl;
      const showUrl = this.showUrl.replace(/:token/g, token);
      const res = await this.http
        .get<LinkEntity>(`${baseUrl}/${showUrl}`, {
          headers: {
            "SESSION-TOKEN": `Token ${termsAcceptanceToken}`,
          },
        })
        .toPromise();

      return new Success(res);
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async deleteFile(
    token: string,
    acceptanceToken: string,
    answer: DocAnswerEntity,
    file: DocFileEntity
  ): Promise<Result> {
    try {
      const baseUrl = environment.apiUrl;
      const endpoint = this.deleteFileUrl
        .replace(/:token/, token)
        .replace(/:documentAnswerId/, answer.id.toString())
        .replace(/:documentFileId/, file.id.toString());
      let res;
      res = await this.http
        .delete(`${baseUrl}/${endpoint}`, {
          headers: {
            "SESSION-TOKEN": `Token ${acceptanceToken}`,
          },
        })
        .toPromise();

      return new Success(res);
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }
}
