import { HttpClient, HttpEventType, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppError } from "@app/shared/util/errors/error";
import { Failure, Result, Success } from "@app/shared/util/types/result";
import { environment } from "@environments/environment";
import { Observable, of } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { DocFileEntity } from "../entities/document-file.entity";

interface SaveArgs {
  documentAnswerId: number;
  token: string;
  acceptanceToken: string;
  file: File;
}

@Injectable({
  providedIn: "root",
})
export class DocAnswerRepository {
  createUrl = "public/link/:token/document_answer/:documentAnswerId/";

  constructor(protected readonly http: HttpClient) {}

  upload(form: SaveArgs): Observable<Result<DocFileEntity> | number> {
    try {
      const formData = new FormData();
      formData.set("file", form.file);

      const endpointUrl = this.createUrl
        .replace(":token", form.token)
        .replace(":documentAnswerId", form.documentAnswerId.toString());
      const url = `${environment.apiUrl}/${endpointUrl}`;

      return this.http
        .post<DocFileEntity>(url, formData, {
          reportProgress: true,
          observe: "events",
          headers: {
            "SESSION-TOKEN": `Token ${form.acceptanceToken}`,
          },
        })
        .pipe(
          filter(
            (event) =>
              event.type === HttpEventType.UploadProgress ||
              event.type === HttpEventType.Response
          ),
          map((event) => {
            if (event.type === HttpEventType.UploadProgress) {
              const progress = event.loaded / event.total;
              return progress;
            } else {
              return new Success((event as HttpResponse<DocFileEntity>).body);
            }
          }),
          catchError((error) => {
            return of(new Failure(AppError.parse(error)));
          })
        );
    } catch (error) {
      return of(new Failure(AppError.parse(error)));
    }
  }
}
