import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  DocFileEntity,
  isImage,
  isPdf,
} from "@app/data/entities/document-file.entity";
import { DeleteUsecase } from "@app/domain/usecases/delete.usecase";
import { UploadUsecase } from "@app/domain/usecases/upload.usecase";
import { ModalController } from "@ionic/angular";
import { AcceptanceUsecase } from "@app/domain/usecases/acceptance.usecase";
import { ImageViewerPage } from "../image-viewer/image-viewer.page";

@Component({
  templateUrl: "./documents.page.html",
  styleUrls: ["./documents.page.scss"],
  providers: [UploadUsecase, DeleteUsecase],
})
export class DocumentsPage implements OnInit {
  @ViewChild("fileInput") fileInput: ElementRef;

  isImage = isImage;
  isPdf = isPdf;

  constructor(
    public acceptance: AcceptanceUsecase,
    public upload: UploadUsecase,
    public deleteUsecase: DeleteUsecase,
    public modalController: ModalController
  ) {}

  async ngOnInit() {}

  async openImage(file: DocFileEntity) {
    const modal = await this.modalController.create({
      component: ImageViewerPage,
      componentProps: {
        url: file.file,
      },
    });
    await modal.present();
  }
}
