import { Injectable } from "@angular/core";
import { DocAnswerEntity } from "@app/data/entities/doc-answer.entity";
import { DocFileEntity } from "@app/data/entities/document-file.entity";
import { LinkEntity } from "@app/data/entities/link.entity";
import { TermsEntity } from "@app/data/entities/terms.entity";
import { LinkRepository } from "@app/data/repositories/link.repository";
import { TermsModalComponent } from "@app/presentation/terms-modal/terms-modal.component";
import { NotFoundError } from "@app/shared/util/errors/error";
import { ModalController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

const OPEN_TERMS_DELAY = 0;

export abstract class AcceptanceState {
  public readonly isInitializing: boolean = false;
  public readonly isFetchingTerms: boolean = false;
  public readonly isAccepting: boolean = false;
  public readonly isFetchingLink: boolean = false;
  public readonly isLinkExpired: boolean = false;
  public readonly isLinkSuccess: boolean = false;
  public readonly isTermsRejected: boolean = false;
  public readonly isTermsAccepted: boolean = false;
  public readonly isAcceptanceGenericError: boolean = false;
}
export class Initializing extends AcceptanceState {
  public readonly isInitializing = true;
}
export class FetchingTerms extends AcceptanceState {
  public readonly isFetchingTerms = true;
  constructor(public readonly linkToken: string) {
    super();
  }
}
export class Accepting extends AcceptanceState {
  public readonly isAccepting = true;
  constructor(public readonly terms: TermsEntity, public readonly email) {
    super();
  }
}
export class FetchingLink extends AcceptanceState {
  public readonly isFetchingLink = true;
  constructor(
    public readonly linkToken: string,
    public readonly acceptanceToken: string
  ) {
    super();
  }
}
export class LinkExpired extends AcceptanceState {
  public readonly isLinkExpired = true;
}
export class LinkSuccess extends AcceptanceState {
  public readonly isLinkSuccess = true;
  constructor(
    public readonly acceptanceToken: string,
    public readonly link: LinkEntity
  ) {
    super();
  }
}
export class TermsRejected extends AcceptanceState {
  public readonly isTermsRejected = true;
}
export class TermsAccepted extends AcceptanceState {
  public readonly isTermsAccepted = true;
  constructor(
    public readonly linkToken: string,
    public readonly acceptanceToken: string
  ) {
    super();
  }
}
export class AcceptanceGenericError extends AcceptanceState {
  public readonly isAcceptanceGenericError = true;
}

@Injectable({
  providedIn: "root",
})
export class AcceptanceUsecase {
  private stateSubject = new BehaviorSubject<AcceptanceState>(
    new Initializing()
  );
  busy$ = this.stateSubject.pipe(
    shareReplay(1),
    map(
      (state) =>
        !state.isLinkSuccess &&
        !state.isLinkExpired &&
        !state.isAcceptanceGenericError
    )
  );
  acceptanceToken$ = this.stateSubject.pipe(
    shareReplay(1),
    map((state) => {
      if (state instanceof LinkSuccess) {
        return state.acceptanceToken;
      }
      return null;
    })
  );
  link$ = this.stateSubject.pipe(
    shareReplay(1),
    map((state) => {
      if (state instanceof LinkSuccess) {
        return state.link;
      }
      return null;
    })
  );
  state$ = this.stateSubject.asObservable();

  constructor(
    private repository: LinkRepository,
    private modalController: ModalController
  ) {}

  async init(linkToken: string): Promise<AcceptanceState> {
    let result: AcceptanceState = new AcceptanceGenericError();

    const acceptanceToken = localStorage.getItem(linkToken);
    if (typeof acceptanceToken === "string") {
      this.stateSubject.next(new FetchingLink(linkToken, acceptanceToken));
      result = await this.fetchLink(linkToken, acceptanceToken);
    } else {
      this.stateSubject.next(new FetchingTerms(linkToken));
      const acceptanceResult = await this.fetchAndAcceptTerms(linkToken);
      this.stateSubject.next(acceptanceResult);
      if (acceptanceResult instanceof Accepting) {
        const acceptanceRequestResult = await this.acceptTerms(
          linkToken,
          acceptanceResult.email
        );
        if (acceptanceRequestResult instanceof TermsAccepted) {
          localStorage.setItem(
            linkToken,
            acceptanceRequestResult.acceptanceToken
          );
          this.stateSubject.next(
            new FetchingLink(linkToken, acceptanceRequestResult.acceptanceToken)
          );
          result = await this.fetchLink(
            linkToken,
            acceptanceRequestResult.acceptanceToken
          );
        } else {
          localStorage.removeItem(linkToken);
          // return result;
          result = acceptanceRequestResult;
        }
      } else {
        localStorage.removeItem(linkToken);
        result = acceptanceResult;
      }
    }
    this.stateSubject.next(result);
    return result;
  }

  updateDocAnswer(answer: DocAnswerEntity, newFile: DocFileEntity) {
    const state = this.stateSubject.value;
    if (state instanceof LinkSuccess) {
      const oldAnswers = state.link.doc_answers;
      const oldAnswer = oldAnswers.find((el) => el.id === answer.id);
      const oldFiles = oldAnswer?.files || [];
      const newAnswer = {
        ...oldAnswer,
        files: [newFile, ...oldFiles],
      };

      const newLink = {
        ...state.link,
        doc_answers: oldAnswers.map((a) =>
          a.id !== answer.id ? a : newAnswer
        ),
      };
      this.stateSubject.next(new LinkSuccess(state.acceptanceToken, newLink));
    }
  }

  private async fetchLink(
    linkToken: string,
    acceptanceToken: string
  ): Promise<AcceptanceState> {
    const result = await this.repository.show(linkToken, acceptanceToken);
    if (result.isSuccess) {
      const success = new LinkSuccess(acceptanceToken, result.data);
      return success;
    } else if (result.error instanceof NotFoundError) {
      const expired = new LinkExpired();
      return expired;
    } else {
      const error = new AcceptanceGenericError();
      return error;
    }
  }

  private async fetchAndAcceptTerms(
    linkToken: string
  ): Promise<AcceptanceState> {
    const result = await this.repository.fetchTerms(linkToken);
    if (result.isSuccess) {
      const terms = result.data;
      const userEmail = await this.showTerms(terms);
      if (userEmail == null) {
        return new TermsRejected();
      } else {
        return new Accepting(terms, userEmail);
      }
    } else if (result.error instanceof NotFoundError) {
      return new LinkExpired();
    } else {
      return new AcceptanceGenericError();
    }
  }

  private async showTerms(terms: TermsEntity): Promise<string> {
    const userEmail = await this.collectAcceptance(terms);
    return userEmail;
  }

  private async collectAcceptance(terms: TermsEntity): Promise<string> {
    await new Promise((resolve) => setTimeout(resolve, OPEN_TERMS_DELAY));
    const modal = await this.modalController.create({
      component: TermsModalComponent,
      componentProps: {
        terms,
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (typeof data === "string" && data.length > 0) {
      const email = data;
      return email;
    } else {
      return null;
    }
  }

  private async acceptTerms(
    linkToken: string,
    email: string
  ): Promise<AcceptanceState> {
    const result = await this.repository.acceptTerms(linkToken, email);
    if (result.isSuccess) {
      return new TermsAccepted(linkToken, result.data.token);
    } else if (result.error instanceof NotFoundError) {
      return new LinkExpired();
    } else {
      return new AcceptanceGenericError();
    }
  }
}
