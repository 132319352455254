import { HasId } from "../base/has-id";

export interface DocFileEntity extends HasId {
  doc_answer: number;
  file: string;
  extension: string;
  name: string;
  size: number;
}

const IMAGE_FORMATS = ["jpg", "gif", "png", "jpeg", "bmp", "gif"];

export function isImage(file: DocFileEntity) {
  const parsedExtension = file.extension?.replace(/\./g, "").toLowerCase();
  return IMAGE_FORMATS.includes(parsedExtension);
}
export function isPdf(file: DocFileEntity) {
  const parsedExtension = file.extension?.replace(/\./g, "").toLowerCase();
  return parsedExtension === "pdf";
}
