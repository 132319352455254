import { Injectable } from "@angular/core";
import { DocAnswerEntity } from "@app/data/entities/doc-answer.entity";
import { DocFileEntity } from "@app/data/entities/document-file.entity";
import { LinkRepository } from "@app/data/repositories/link.repository";
import { AlertController, ToastController } from "@ionic/angular";
import { combineLatest } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { AcceptanceUsecase } from "./acceptance.usecase";

@Injectable()
export class DeleteUsecase {
  busy: boolean;
  deleted: { [id: string]: boolean } = {};

  constructor(
    private repo: LinkRepository,
    private alertController: AlertController,
    private acceptance: AcceptanceUsecase,
    private toastController: ToastController
  ) {}

  async delete(answer: DocAnswerEntity, doc: DocFileEntity) {
    const alert = await this.alertController.create({
      header: "Atenção",
      message: `Deseja deletar o documento? Essa ação <strong>não</strong> poderá ser desfeita.`,
      buttons: [
        {
          text: "Não",
          role: "cancel",
          cssClass: "danger",
          handler: (blah) => {},
        },
        {
          text: "Sim",
          handler: () => {
            this.handleDelete(answer, doc);
          },
        },
      ],
    });

    await alert.present();
  }

  private handleDelete(answer: DocAnswerEntity, doc: DocFileEntity) {
    if (this.busy !== true) {
      this.busy = true;
      combineLatest([this.acceptance.acceptanceToken$, this.acceptance.link$])
        .pipe(
          take(1),
          switchMap(([acceptanceToken, link]) => {
            this.deleted[doc.id] = true;
            return this.repo.deleteFile(
              link.token,
              acceptanceToken,
              answer,
              doc
            );
          })
        )
        .subscribe(async (res) => {
          this.busy = false;
          if (res.isFailure) {
            this.deleted[doc.id] = false;
            const toast = await this.toastController.create({
              color: "danger",
              message: "Não foi possível remover o documento.",
              duration: 3000,
            });
            await toast.present();
          }
        });
    }
  }
}
